import { RouteComponentProps, Router } from '@reach/router';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { AddNewPortfolioInvestmentProvider } from 'contexts/saver/investment-setup';
import { PortfolioRegistrationProvider } from 'contexts/saver/portfolio-registration';
import { AddNewPortfolioSelectionProvider } from 'contexts/saver/portfolio-selection';
import { PageNotFound } from 'pages/page-not-found';
import { VoyagerOnboardingDeposit } from 'pages/voyager-onboarding-deposit';
import { VoyagerOnboardingInvestmentPlan } from 'pages/voyager-onboarding-investment-plan';
import { VoyagerPortfolioRegistrationSuccess } from 'pages/voyager-portfolio-registration-success';
import { VoyagerPortfolioRegistrationSummary } from 'pages/voyager-portfolio-registration-summary';
import { VoyagerSelectPortfolio } from 'pages/voyager-select-portfolio';
import { VoyagerSRM } from 'pages/voyager-srm';
import { VoyagerTMD } from 'pages/voyager-tmd';
import { VoyagerTmdPreapprovedPortfolios } from 'pages/voyager-tmd-pre-approved-portfolios';
import React from 'react';

export const VoyagerPortfolioRegistration: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );

  return (
    <PortfolioRegistrationProvider>
      <AddNewPortfolioSelectionProvider>
        <AddNewPortfolioInvestmentProvider>
          <Router>
            <VoyagerSelectPortfolio path="/" />
            <VoyagerTmdPreapprovedPortfolios path="pre-approved" />
            {isVerifiedUser && (
              <VoyagerOnboardingDeposit path="invest/deposit" />
            )}
            <VoyagerOnboardingInvestmentPlan path="invest/investment-plan" />
            <VoyagerPortfolioRegistrationSummary path="summary" />
            <VoyagerPortfolioRegistrationSuccess path="success" />
            <VoyagerTMD path="tmd" />
            <VoyagerSRM path="srm" />
            <PageNotFound default={true} />
          </Router>
        </AddNewPortfolioInvestmentProvider>
      </AddNewPortfolioSelectionProvider>
    </PortfolioRegistrationProvider>
  );
};
