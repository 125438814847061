import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { useLogout } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  fromProtoPortfolio,
  SaverPortfolio,
  SaverTMDResult,
  toProtoPortfolio,
  useSaverPortfolioFlags,
  useSubmitPreApprovedTargetMarketDeterminationAnswers,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerSelectPortfolio } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerSelectPortfolio';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { getSpace } from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { SaverPortfolio as SaverPortfolioExternal } from '@spaceship-fspl/types/externalapi';
import { tmdDetailsForPortfolio } from '@spaceship-fspl/voyager';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { ResourceList } from 'components/resource-list';
import { useNotifications } from 'contexts/notifications';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE, PORTFOLIO_PERFORMANCE } from 'helpers/errors';
import { voyagerPortfolios } from 'helpers/portfolios';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  VoyagerPortfolioSelector,
  VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment,
} from '../components/voyager-portfolio-selector';

export enum VoyagerPopupModals {
  INVESTABLE_ASSETS = 'investable-assets',
  PORTFOLIO_MIX = 'portfolio-mix',
}

export const VoyagerSelectPortfolio: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const [error, setError] = useState<string>();
  const [selectedPortfolio, setSelectedPortfolio] = useState<SaverPortfolio>();
  const track = useTrack();
  const logout = useLogout();
  const {
    currentPortfolios,
    variant,
    onPortfolioSelect,
    tmdDetailsByPortfolio,
    onTMDEligible,
    navigateToTMDForm,
  } = usePortfolioSelection();

  const { popToast } = useNotifications();
  const flagsData = useSaverPortfolioFlags();
  const resp = useQuery<WebAppVoyagerSelectPortfolio>(
    gql`
      query WebAppVoyagerSelectPortfolio {
        saverPortfolioInformations {
          id
          ...VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment
        }
      }
      ${VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment}
    `,
    {
      onError: () => {
        popToast({
          message: PORTFOLIO_PERFORMANCE,
          level: 'error',
        });
      },
    },
  );
  const portfolioInformation = resp?.data?.saverPortfolioInformations;
  const config = selectedPortfolio
    ? voyagerPortfolios[selectedPortfolio]
    : undefined;
  const pdsLink = config?.pds ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const tmdLink = config?.tmd ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const selectedPortfolioTitle = config?.title ?? '';
  const userHasHoldingsInAllPortfolios =
    portfolioInformation?.length === currentPortfolios?.length;

  const [setPreApprovedTMDAnswers, setPreApprovedTMDAnswersMeta] =
    useSubmitPreApprovedTargetMarketDeterminationAnswers();

  const handleOnSubmit: (
    gqlPortfolio?: SaverPortfolio,
  ) => Promise<void> = async (gqlPortfolio) => {
    if (variant === 'onboarding') {
      track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_PORTFOLIO_CONFIRM);
    }

    // if no portfolio is selected, set error state and stop submission
    if (!gqlPortfolio) {
      setError('Portfolio is required');
      return;
    }

    // old grpc saver portfolio
    const portfolio = toProtoPortfolio(gqlPortfolio);
    // get existing TMD details
    let selectedProductTMDDetails =
      portfolio && tmdDetailsByPortfolio[portfolio];

    if (selectedProductTMDDetails === SaverPortfolioExternal.Enum.UNKNOWN) {
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
      return;
    }

    if (
      selectedProductTMDDetails?.preApproved &&
      !selectedProductTMDDetails.resubmissionConfirmationRequired
    ) {
      try {
        // trigger mutation to set pre-approved answers in graph
        const result = await setPreApprovedTMDAnswers({
          variables: {
            input: {
              portfolio: gqlPortfolio,
            },
          },
        });

        // get updated tmd details
        selectedProductTMDDetails = tmdDetailsForPortfolio(
          gqlPortfolio,
          result.data?.submitPreApprovedTargetMarketDetermination,
        );
      } catch {
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
    onPortfolioSelect(portfolio);

    if (
      selectedProductTMDDetails?.result === SaverTMDResult.OK ||
      selectedProductTMDDetails?.result === SaverTMDResult.MANUALLY_APPROVED
    ) {
      onTMDEligible();
    } else {
      navigateToTMDForm();
    }
  };

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createProductInstanceAvailable =
    saverPortfolioFlags?.createProductInstanceAvailable ?? true;

  const handlePortfolioSelectionChange: (
    gqlPortfolio?: SaverPortfolio,
  ) => void = (gqlPortfolio) => {
    if (!gqlPortfolio) {
      setError('Portfolio is required');
      return;
    }
    const portfolio = toProtoPortfolio(gqlPortfolio);
    onPortfolioSelect(portfolio);
    setSelectedPortfolio(gqlPortfolio);
    setError('');
  };

  if (!createProductInstanceAvailable) {
    return (
      <Error
        title="Select your portfolio"
        subtitle={
          saverPortfolioFlags?.createProductInstanceNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            if (variant === 'onboarding') {
              logout();
            } else {
              window.history.back();
            }
          },
          trackingProperties: {
            name: 'create_product_instance_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <Stack spaceY="md">
      <Columns alignX="center" spaceY="xl">
        <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
          <Header>
            <PageHeading
              title="Select your portfolio"
              subtitle={`Invest in one of our professionally-managed portfolios made up of the companies you know and use every day.`}
            />
          </Header>
        </Columns.Column>
      </Columns>
      <Box>
        {error && (
          <Box marginBottom="md">
            <Inline spaceX="xs" alignY="center" alignX="center">
              <Box lineHeight={0}>
                <FeatherAlertTriangleIcon size="md" color="red.100" />
              </Box>
              <Text color="red.100" variant={2} isBold>
                {error}
              </Text>
            </Inline>
          </Box>
        )}
        <VoyagerPortfolioSelector
          portfoliosHeldByUser={
            currentPortfolios?.map((p) => fromProtoPortfolio(p) || null) ?? []
          }
          error={error}
          onSelectionChange={handlePortfolioSelectionChange}
          portfolios={portfolioInformation ?? []}
          variant="onboarding"
        />
        <Footer>
          <Columns alignX="center" spaceY="xl">
            <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
              <Box marginBottom={'md'}>
                <Text variant={2} color="neutral.085" align="center">
                  All fees and costs are inclusive of GST and net of expected
                  reduced input tax credits. Third-party fees may apply. Find
                  out more by reading the PDS.
                  <br /> <br />
                  This information is general in nature as it has been prepared
                  without taking account of your objectives, financial situation
                  or needs. You should assess your financial situation and
                  needs, consider seeking professional financial advice, and
                  read the PDS and other important documents before making an
                  investment decision.
                </Text>
              </Box>

              <Box marginY={'md'}>
                <Stack spaceY="md">
                  <Stack spaceY="sm">
                    <Heading
                      data-testid="signup-heading"
                      variant={4}
                      isBold
                      component="h3"
                    >
                      Sign up{' '}
                      {selectedPortfolioTitle
                        ? `for the ${selectedPortfolioTitle}`
                        : `to a${
                            variant === 'add' ? ' new' : ''
                          } Spaceship Voyager portfolio`}
                    </Heading>

                    <Text variant={2}>
                      By clicking the{' '}
                      <strong>&apos;Confirm selection&apos;</strong> button, I
                      confirm I understand and accept the important documents
                      below and confirm that the product is consistent with my
                      objectives, financial situation and needs.
                    </Text>
                  </Stack>

                  <Box>
                    <Divider color="neutral.050" />

                    <ResourceList
                      data-testid="external-resources"
                      resources={[
                        {
                          label: 'Product Disclosure Statement',
                          link: pdsLink,
                        },
                        {
                          label: 'Reference Guide',
                          link: InternalRoutes.REFERENCE_GUIDE,
                        },
                        {
                          label: 'Target Market Determination Statement',
                          link: tmdLink,
                        },
                      ]}
                    />

                    <Divider color="neutral.050" />
                  </Box>
                </Stack>
              </Box>

              <Box marginY={'md'}>
                <PageFormButtonContainer>
                  <PageFormContinueButton
                    onClick={() => handleOnSubmit(selectedPortfolio)}
                    isDisabled={userHasHoldingsInAllPortfolios}
                    data-testid="confirm-portfolio-selection"
                    isLoading={setPreApprovedTMDAnswersMeta.loading}
                    trackingProperties={{
                      name:
                        variant === 'onboarding'
                          ? 'signup_portfolio_submit'
                          : 'portfolio_registration_select_portfolio_submit',
                    }}
                  >
                    Confirm selection
                  </PageFormContinueButton>
                </PageFormButtonContainer>
              </Box>
            </Columns.Column>
          </Columns>
        </Footer>
      </Box>
    </Stack>
  );
};

const Footer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: ${getSpace('md')};
  margin-right: ${getSpace('md')};
  justify-content: space-between;
`;

const Header = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: ${getSpace('md')};
  margin-right: ${getSpace('md')};
  justify-content: space-between;
`;
