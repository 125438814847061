import { gql } from '@apollo/client';
import {
  SaverPortfolio,
  SaverTMDResult,
  ScheduleFrequency,
} from '@spaceship-fspl/graphql';
import { SaverTMDDetails } from '@spaceship-fspl/graphql/src/__generated__/SaverTMDDetails';
import { SetSaverTargetMarketDeterminationAnswers } from '@spaceship-fspl/graphql/src/__generated__/SetSaverTargetMarketDeterminationAnswers';
import { ShowTmdManuallyApprovedCard_QueryFragment as ShowTmdManuallyApprovedCard_QueryFragmentType } from '@spaceship-fspl/graphql/src/__generated__/ShowTmdManuallyApprovedCard_QueryFragment';
import type { SubmitPreApprovedTargetMarketDeterminationAnswers } from '@spaceship-fspl/graphql/src/__generated__/SubmitPreApprovedTargetMarketDeterminationAnswers';
import { getToday, stringToDate } from '@spaceship-fspl/helpers';
import { SaverPortfolio as OldSaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { differenceInDays, isAfter, isBefore } from 'date-fns';

export const getPortfolioName = (
  portfolioEnum?: SaverPortfolio | OldSaverPortfolio.Enum | null,
): string => {
  switch (portfolioEnum) {
    case SaverPortfolio.INDEX:
    case OldSaverPortfolio.Enum.INDEX:
      return 'Origin';

    case SaverPortfolio.UNIVERSE:
    case OldSaverPortfolio.Enum.UNIVERSE:
      return 'Universe';

    case SaverPortfolio.EARTH:
    case OldSaverPortfolio.Enum.EARTH:
      return 'Earth';

    case SaverPortfolio.GALAXY:
    case OldSaverPortfolio.Enum.GALAXY:
      return 'Galaxy';

    case SaverPortfolio.EXPLORER:
    case OldSaverPortfolio.Enum.EXPLORER:
      return 'Explorer';

    default:
      return '';
  }
};

export type TransactionFilterValues =
  | 'all'
  | 'one-off'
  | 'plan'
  | 'withdrawal'
  | 'referral'
  | 'promo'
  | 'boost'
  | 'distribution'
  | 'fee';

export const getTransactionFilters = (
  portfolio?: SaverPortfolio,
): Array<{
  value: TransactionFilterValues;
  label: string;
}> => {
  if (!portfolio) {
    return [];
  }

  const filters: Array<{
    value: TransactionFilterValues;
    label: string;
  }> = [
    { value: 'all', label: 'Show all types' },
    { value: 'one-off', label: 'One-off investments' },
    { value: 'plan', label: 'Investment plan' },
    { value: 'withdrawal', label: 'Withdrawals' },
    { value: 'referral', label: 'Referrals' },
    { value: 'promo', label: 'Promotion' },
  ];

  if (portfolio === SaverPortfolio.EARTH) {
    filters.push({ value: 'distribution', label: 'Distribution' });
  }

  filters.push(
    { value: 'fee', label: 'Fees' },
    { value: 'boost', label: 'Boost' },
  );

  return filters;
};

const YEAR_FREQUENCY: Record<ScheduleFrequency, number> = {
  [ScheduleFrequency.WEEKLY]: 52,
  [ScheduleFrequency.FORTNIGHTLY]: 26,
  [ScheduleFrequency.MONTHLY]: 12,
  [ScheduleFrequency.ONE_TIME]: 1,
};

interface CalcGoalProjectionInputData {
  initialDeposit: number;
  returnRate: number;
  monthlyInvestmentAmount: number;
  targetAmount: number;
}

interface CalcGoalProjectionResultsData {
  investments: number;
  returns: number;
  total: number;
}

const MAX_PROJECTION_YEARS = 500;

/**
 * MoneySmart Calculator:
 * https://moneysmart.gov.au/budgeting/compound-interest-calculator
 */
export const calcGoalProjection = ({
  initialDeposit,
  returnRate,
  monthlyInvestmentAmount,
  targetAmount,
}: CalcGoalProjectionInputData): Array<CalcGoalProjectionResultsData> => {
  const totalYearlyInvestments =
    monthlyInvestmentAmount * YEAR_FREQUENCY[ScheduleFrequency.MONTHLY];
  const projection: Array<CalcGoalProjectionResultsData> = [];
  let yearTotal = initialDeposit;
  let yearReturns = 0;

  if (
    initialDeposit < 0 ||
    totalYearlyInvestments < 0 ||
    returnRate < 0 ||
    targetAmount < 0
  ) {
    console.warn('calcGoalProjection: values should not be negative');
    return [];
  }

  if ((!initialDeposit || !returnRate) && !monthlyInvestmentAmount) {
    // will never reach goal
    return [];
  }

  for (
    let numOfYears = 1;
    yearTotal < targetAmount && numOfYears < MAX_PROJECTION_YEARS;
    numOfYears++
  ) {
    const investments = initialDeposit + totalYearlyInvestments * numOfYears;
    yearReturns = yearTotal * (returnRate / 100) + yearReturns;
    yearTotal = investments + yearReturns;

    projection.push({
      investments,
      returns: yearReturns,
      total: yearTotal,
    });
  }

  return projection;
};

export const calcTotalInvestmentsByFrequency = (
  investments: Array<{ amount: number; frequency: ScheduleFrequency }>,
  frequency: ScheduleFrequency,
): number => {
  const resultFrequency = YEAR_FREQUENCY[frequency];

  return investments.reduce(
    (total, investment) =>
      investment.frequency === ScheduleFrequency.ONE_TIME
        ? total
        : total +
          (investment.amount * YEAR_FREQUENCY[investment.frequency]) /
            resultFrequency,
    0,
  );
};

interface CalcPresentValueInputData {
  amount: number;
  inflationRate: number;
  timePeriodInYears: number;
}

export const calcPresentValue = ({
  amount,
  inflationRate,
  timePeriodInYears,
}: CalcPresentValueInputData): number =>
  amount / Math.pow(1 + inflationRate / 100, timePeriodInYears);

export type TMDDetails = {
  result: SaverTMDResult | null;
  submissionAvailable: boolean | null;
  resubmissionConfirmationRequired: boolean | null;
  reviewedAt: string | null;
  createdAt: string | null;
  preApproved: boolean | null;
};

export function tmdDetailsForPortfolio(
  portfolio: SaverPortfolio | OldSaverPortfolio.Enum,
  data?:
    | SaverTMDDetails['contact']
    | SetSaverTargetMarketDeterminationAnswers['setSaverTargetMarketDeterminationAnswers']
    | SubmitPreApprovedTargetMarketDeterminationAnswers['submitPreApprovedTargetMarketDetermination']
    | null,
): TMDDetails | undefined {
  switch (portfolio) {
    case SaverPortfolio.INDEX: {
      return data?.account?.indexSaverTMDDetails;
    }
    case OldSaverPortfolio.Enum.INDEX: {
      return data?.account?.indexSaverTMDDetails;
    }
    case SaverPortfolio.EARTH: {
      return data?.account?.earthSaverTMDDetails;
    }
    case OldSaverPortfolio.Enum.EARTH: {
      return data?.account?.earthSaverTMDDetails;
    }
    case SaverPortfolio.UNIVERSE: {
      return data?.account?.universeSaverTMDDetails;
    }
    case OldSaverPortfolio.Enum.UNIVERSE: {
      return data?.account?.universeSaverTMDDetails;
    }
    case SaverPortfolio.GALAXY: {
      return data?.account?.galaxySaverTMDDetails;
    }
    case OldSaverPortfolio.Enum.GALAXY: {
      return data?.account?.galaxySaverTMDDetails;
    }
    case SaverPortfolio.EXPLORER: {
      return data?.account?.explorerSaverTMDDetails;
    }
    case OldSaverPortfolio.Enum.EXPLORER: {
      return data?.account?.explorerSaverTMDDetails;
    }
    default:
      return;
  }
}

export function tmdResultEligible(
  result: SaverTMDResult | undefined | null,
): boolean {
  switch (result) {
    case SaverTMDResult.OK:
      return true;
    case SaverTMDResult.MANUALLY_APPROVED:
      return true;
    default:
      return false;
  }
}

export enum TmdIneligibilityReason {
  INCORRECT_ANSWERS,
  TOO_MANY_ATTEMPTS,
  NO_SUGGESTED_PORTFOLIOS,
}

export const tmdContentForIneligibilityReason = (
  reason: TmdIneligibilityReason,
): { title: string; description: string } => {
  switch (reason) {
    case TmdIneligibilityReason.INCORRECT_ANSWERS: {
      return {
        title: 'Hmm...',
        description:
          'It doesn’t look likely that you’re in the target market for this portfolio, which means that it may not meet your needs.\n\nIt’s worth reviewing our Product Disclosure Statement and Target Market Determination to understand who the portfolio is designed for, the potential returns and the level of investment risk.\n\nYou also may find it useful to speak to a financial adviser.\n\nYou’re welcome to come back if your investment objectives, financial situation or needs change.',
      };
    }
    case TmdIneligibilityReason.TOO_MANY_ATTEMPTS: {
      return {
        title: 'Hmm...',
        description:
          'We understand your interest in this product and we appreciate your enthusiasm. However, we have a responsibility to ensure that our products are suitable for our target market.\n\nBased on the information you’ve provided in previous attempts, it seems that you may not align with our target market.\n\nWe suggest you read our PDS and TMD again. It could also be beneficial for you to speak with a financial adviser. If your circumstances change in the future, you’re welcome to come back.',
      };
    }
    case TmdIneligibilityReason.NO_SUGGESTED_PORTFOLIOS: {
      return {
        title: 'Hmm...',
        description:
          'It doesn’t look likely that you’re in the target market for this portfolio, which means that it may not meet your needs.\n\nIt’s worth reviewing our Product Disclosure Statement and Target Market Determination to understand who the portfolio is designed for, the potential returns and the level of investment risk.\n\nYou also may find it useful to speak to a financial adviser.\n\nYou’re welcome to come back if your investment objectives, financial situation or needs change.',
      };
    }
  }
};

type RecentTMDDetails = {
  createdAt?: string | null;
  reviewedAt?: string | null;
  result?: SaverTMDResult | null;
};

export const getMostRecentlyTmdRejectedPortfolio = (
  data?: SaverTMDDetails,
): SaverPortfolio | undefined => {
  const getDate = (details: RecentTMDDetails | undefined): Date | undefined => {
    switch (details?.result) {
      case SaverTMDResult.NOT_OK:
        if (details.createdAt) {
          return stringToDate(details.createdAt);
        }
        return;

      case SaverTMDResult.MANUALLY_REJECTED:
        if (details?.reviewedAt) {
          return stringToDate(details.reviewedAt);
        }
        return;

      default:
        return;
    }
  };

  const mostRecentlyRejected = [
    {
      portfolio: SaverPortfolio.INDEX,
      details: data?.contact.account?.indexSaverTMDDetails,
    },
    {
      portfolio: SaverPortfolio.UNIVERSE,
      details: data?.contact.account?.universeSaverTMDDetails,
    },
    {
      portfolio: SaverPortfolio.EARTH,
      details: data?.contact.account?.earthSaverTMDDetails,
    },
    {
      portfolio: SaverPortfolio.GALAXY,
      details: data?.contact.account?.galaxySaverTMDDetails,
    },
    {
      portfolio: SaverPortfolio.EXPLORER,
      details: data?.contact.account?.explorerSaverTMDDetails,
    },
  ].reduce(
    (selected, current) => {
      // sort by reviewed at/created at date desc
      const selectedDate = getDate(selected?.details);
      const currrentDate = getDate(current.details);

      if (selectedDate && currrentDate) {
        return isAfter(currrentDate, selectedDate) ? current : selected;
      } else if (selectedDate) {
        return selected;
      } else if (currrentDate) {
        return current;
      } else {
        return undefined;
      }
    },
    undefined as
      | undefined
      | {
          portfolio: SaverPortfolio;
          details: RecentTMDDetails | undefined;
        },
  );
  return mostRecentlyRejected?.portfolio;
};

export const getMostRecentlyTmdApprovedPortfolio = (
  data?:
    | SaverTMDDetails['contact']
    | SetSaverTargetMarketDeterminationAnswers['setSaverTargetMarketDeterminationAnswers']
    | SubmitPreApprovedTargetMarketDeterminationAnswers['submitPreApprovedTargetMarketDetermination']
    | null,
): OldSaverPortfolio.Enum | undefined => {
  const getDate = (details: RecentTMDDetails | undefined): Date | undefined => {
    switch (details?.result) {
      case SaverTMDResult.OK:
        if (details.createdAt) {
          return stringToDate(details.createdAt);
        }
        return;

      case SaverTMDResult.MANUALLY_APPROVED:
        if (details?.reviewedAt) {
          return stringToDate(details.reviewedAt);
        }
        return;

      default:
        return;
    }
  };
  const mostRecentlyApproved = [
    {
      portfolio: OldSaverPortfolio.Enum.INDEX,
      details: data?.account?.indexSaverTMDDetails,
    },
    {
      portfolio: OldSaverPortfolio.Enum.UNIVERSE,
      details: data?.account?.universeSaverTMDDetails,
    },
    {
      portfolio: OldSaverPortfolio.Enum.EARTH,
      details: data?.account?.earthSaverTMDDetails,
    },
    {
      portfolio: OldSaverPortfolio.Enum.GALAXY,
      details: data?.account?.galaxySaverTMDDetails,
    },
    {
      portfolio: OldSaverPortfolio.Enum.EXPLORER,
      details: data?.account?.explorerSaverTMDDetails,
    },
  ].reduce(
    (selected, current) => {
      // sort by reviewed at/created at date desc
      const selectedDate = getDate(selected?.details);
      const currrentDate = getDate(current.details);

      if (selectedDate && currrentDate) {
        return isAfter(currrentDate, selectedDate) ? current : selected;
      } else if (selectedDate) {
        return selected;
      } else if (currrentDate) {
        return current;
      } else {
        return undefined;
      }
    },
    undefined as
      | undefined
      | {
          portfolio: OldSaverPortfolio.Enum;
          details: RecentTMDDetails | undefined;
        },
  );

  return mostRecentlyApproved?.portfolio;
};

export const ShowTmdManuallyApprovedCard_QueryFragment = gql`
  fragment ShowTmdManuallyApprovedCard_QueryFragment on Query {
    indexTmdManuallyApprovedCardDismissible: dismissible(
      id: $tmdManuallyApprovedIndexCardDismissibleId
    ) {
      id
      hidden
    }
    universeTmdManuallyApprovedCardDismissible: dismissible(
      id: $tmdManuallyApprovedUniverseCardDismissibleId
    ) {
      id
      hidden
    }
    earthTmdManuallyApprovedCardDismissible: dismissible(
      id: $tmdManuallyApprovedEarthCardDismissibleId
    ) {
      id
      hidden
    }
    galaxyTmdManuallyApprovedCardDismissible: dismissible(
      id: $tmdManuallyApprovedGalaxyCardDismissibleId
    ) {
      id
      hidden
    }
    explorerTmdManuallyApprovedCardDismissible: dismissible(
      id: $tmdManuallyApprovedExplorerCardDismissibleId
    ) {
      id
      hidden
    }
    contact {
      id
      account {
        id
        saverProductInstances {
          id
          createdAt
          primary
          portfolio
          upcomingSchedule {
            id
          }
          latestInvestments: investments {
            id
            transactions(limit: 1) {
              id
              ... on Application {
                schedule {
                  id
                  ...scheduleScalars
                }
              }
            }
          }
        }
        indexSaverTMDDetails: saverTMDDetails(portfolio: INDEX) {
          ...saverTmdDetailsFields
        }
        universeSaverTMDDetails: saverTMDDetails(portfolio: UNIVERSE) {
          ...saverTmdDetailsFields
        }
        earthSaverTMDDetails: saverTMDDetails(portfolio: EARTH) {
          ...saverTmdDetailsFields
        }
        galaxySaverTMDDetails: saverTMDDetails(portfolio: GALAXY) {
          ...saverTmdDetailsFields
        }
        explorerSaverTMDDetails: saverTMDDetails(portfolio: EXPLORER) {
          ...saverTmdDetailsFields
        }
      }
    }
  }
`;

export const getShowTmdManuallyApprovedCard = (
  portfolio: SaverPortfolio,
  data?: ShowTmdManuallyApprovedCard_QueryFragmentType,
): boolean => {
  const allProducts = data?.contact.account?.saverProductInstances;
  const product = allProducts?.find((spi) => spi.portfolio === portfolio);
  let productTmdDetails;
  let isDismissed = false;

  switch (portfolio) {
    case SaverPortfolio.INDEX:
      productTmdDetails = data?.contact.account?.indexSaverTMDDetails;
      isDismissed =
        data?.indexTmdManuallyApprovedCardDismissible?.hidden ?? false;
      break;

    case SaverPortfolio.UNIVERSE:
      productTmdDetails = data?.contact.account?.universeSaverTMDDetails;
      isDismissed =
        data?.universeTmdManuallyApprovedCardDismissible?.hidden ?? false;
      break;

    case SaverPortfolio.EARTH:
      productTmdDetails = data?.contact.account?.earthSaverTMDDetails;
      isDismissed =
        data?.earthTmdManuallyApprovedCardDismissible?.hidden ?? false;
      break;

    case SaverPortfolio.GALAXY:
      productTmdDetails = data?.contact.account?.galaxySaverTMDDetails;
      isDismissed =
        data?.galaxyTmdManuallyApprovedCardDismissible?.hidden ?? false;
      break;

    case SaverPortfolio.EXPLORER:
      productTmdDetails = data?.contact.account?.explorerSaverTMDDetails;
      isDismissed =
        data?.explorerTmdManuallyApprovedCardDismissible?.hidden ?? false;
      break;
  }

  if (!allProducts || !product || !productTmdDetails) {
    return false;
  }

  const signupProductId = allProducts.reduce(
    (selectedProduct, currentProduct) => {
      if (selectedProduct) {
        return isBefore(
          new Date(currentProduct.createdAt),
          new Date(selectedProduct.createdAt),
        )
          ? currentProduct
          : selectedProduct;
      }
      return currentProduct;
    },
    allProducts[0],
  )?.id;
  const reviewedAtDate = productTmdDetails?.reviewedAt
    ? new Date(productTmdDetails.reviewedAt)
    : undefined;

  if (
    // 1. Only for new portfolios in existing account
    signupProductId !== product.id &&
    // 2. TMD was manually approved
    productTmdDetails.result === SaverTMDResult.MANUALLY_APPROVED &&
    // 3. Card was not dismissed previously
    !isDismissed &&
    // 4. No investment plan set
    !product.upcomingSchedule &&
    // 5. No deposits
    product.latestInvestments?.transactions.length === 0 &&
    // 6. TMD approval was within the last 7 days
    reviewedAtDate &&
    differenceInDays(getToday(), reviewedAtDate) <= 7
  ) {
    return true;
  }

  return false;
};

export const isPercyPortfolio = (portfolio: SaverPortfolio): boolean =>
  portfolio === SaverPortfolio.GALAXY || portfolio === SaverPortfolio.EXPLORER;
