import { navigate, Redirect, RouteComponentProps, Router } from '@reach/router';
import { useCanReadSaver } from '@spaceship-fspl/auth';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { OnboardingBankAccountSubmissionProvider } from 'contexts/bank-account-verification';
import { OnboardingInvestmentProvider } from 'contexts/saver/investment-setup';
import { OnboardingRequestProvider } from 'contexts/saver/onboarding';
import { OnboardingPortfolioSelectionProvider } from 'contexts/saver/portfolio-selection';
import { VoyagerMobileApp } from 'pages/mobile-apps';
import { PageNotFound } from 'pages/page-not-found';
import { ResumeOnboarding } from 'pages/resume-onboarding';
import { Routes } from 'pages/routes';
import { VoyagerOnboardingTaxResidency } from 'pages/tax-residency';
import { VoyagerCrossSellStart } from 'pages/voyager-cross-sell-start';
import { VoyagerOnboardingDeposit } from 'pages/voyager-onboarding-deposit';
import { VoyagerOnboardingInvestmentPlan } from 'pages/voyager-onboarding-investment-plan';
import { VoyagerOnboardingSummary } from 'pages/voyager-onboarding-summary';
import { VoyagerSelectPortfolio } from 'pages/voyager-select-portfolio';
import { VoyagerSRM } from 'pages/voyager-srm';
import { VoyagerTMD } from 'pages/voyager-tmd';
import { VoyagerTmdPreapprovedPortfolios } from 'pages/voyager-tmd-pre-approved-portfolios';
import { VoyagerTMDSuccess } from 'pages/voyager-tmd-success';
import React, { FC, useEffect } from 'react';

import { VoyagerOnboardingGreenID } from './greenid';
import { BankAccountRoutes } from './helpers';

export const Onboarding: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const canReadSaver = useCanReadSaver();

  useEffect(() => {
    if (canReadSaver) {
      navigate(Routes.ONBOARDING_MOBILE_APPS);
    }
  }, [canReadSaver]);

  return (
    <OnboardingRequestProvider>
      <OnboardingPortfolioSelectionProvider>
        <OnboardingInvestmentProvider>
          <Router>
            <ResumeOnboarding path="/" />
            <VoyagerOnboarding path="voyager/*" />
            <VoyagerMobileApp path="mobile-apps" />
            <PageNotFound default={true} />
          </Router>
        </OnboardingInvestmentProvider>
      </OnboardingPortfolioSelectionProvider>
    </OnboardingRequestProvider>
  );
};

export const VoyagerOnboarding: FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  return (
    <Router>
      <VoyagerCrossSellStart path="start" />
      <VoyagerOnboardingTaxResidency path="tax-residency" />
      <VoyagerTMD path="tmd" />
      <VoyagerTMDSuccess path="tmd/success" />
      <VoyagerSRM path="srm" />
      <OnboardingBankAccountSubmissionProvider path="bank-account">
        <BankAccountRoutes path="/*" />
      </OnboardingBankAccountSubmissionProvider>
      <VoyagerSelectPortfolio path="portfolio" />
      <VoyagerTmdPreapprovedPortfolios path="pre-approved" />
      <VoyagerOnboardingGreenID path="id-verification/*" />
      <VoyagerOnboardingInvest path="invest/*" />
      <VoyagerOnboardingSummary path="summary" />
      <PageNotFound default={true} />
    </Router>
  );
};

const VoyagerOnboardingInvest: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const isUserVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  return (
    <Router>
      {isUserVerified ? (
        <>
          <Redirect from="/" to="deposit" />
          <VoyagerOnboardingDeposit path="deposit" />
        </>
      ) : (
        <Redirect from="/" to="investment-plan" />
      )}
      <VoyagerOnboardingInvestmentPlan path="investment-plan/" />
      <PageNotFound default={true} />
    </Router>
  );
};
