import { gql } from '@apollo/client';
import { useLogout } from '@spaceship-fspl/auth';
import {
  Box,
  Stack,
  useSetEqualClientHeights,
} from '@spaceship-fspl/components';
import {
  fromProtoPortfolio,
  SaverPortfolio,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerSelectPortfolio_saverPortfolioInformations } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerSelectPortfolio';
import { getSpace, match } from '@spaceship-fspl/styles';
import { SaverPortfolio as SaverPortfolioExternal } from '@spaceship-fspl/types/externalapi';
import { Error } from 'components/layouts/error';
import { VoyagerInvestableAssetsModal } from 'components/voyager-investable-assets-modal';
import { VoyagerPortfolioMixModal } from 'components/voyager-portfolio-mix-modal';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import React, { createRef, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { voyagerPortfolios } from '../helpers/portfolios';
import {
  VoyagerPortfolioCard,
  VoyagerPortfolioCard_SaverPortfolioInformationFragment,
} from './voyager-portfolio-card';

export enum VoyagerPopupModals {
  INVESTABLE_ASSETS = 'investable-assets',
  PORTFOLIO_MIX = 'portfolio-mix',
}

export const VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment = gql`
  fragment VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment on SaverPortfolioInformation {
    id
    ...VoyagerPortfolioCard_SaverPortfolioInformationFragment
  }
  ${VoyagerPortfolioCard_SaverPortfolioInformationFragment}
`;

interface VoyagerPortfolioSelectorProps {
  onSelectionChange: (portfolio?: SaverPortfolio) => void;
  portfoliosHeldByUser?: (SaverPortfolio | null)[];
  portfolios?:
    | (WebAppVoyagerSelectPortfolio_saverPortfolioInformations | null)[]
    | null;
  error?: string;
  variant: 'onboarding' | 'add';
}

export const VoyagerPortfolioSelector: React.FC<
  React.PropsWithChildren<VoyagerPortfolioSelectorProps>
> = ({
  onSelectionChange,
  error,
  portfoliosHeldByUser,
  portfolios,
  variant,
}) => {
  const [currentPortfolioName, setCurrentPortfolioName] = useState('');
  const [investableAssetsModalPortfolio, setInvestableAssetsPortfolioModal] =
    useState<SaverPortfolioExternal.Enum | null>(null);
  const [portfolioMixModalPortfolio, setPortfolioMixModalPortfolio] =
    useState<SaverPortfolioExternal.Enum | null>(null);
  const logout = useLogout();

  const handleTooltipModal = (
    e: React.SyntheticEvent,
    {
      title,
      modal,
      portfolio,
      isDisabled,
    }: {
      modal: VoyagerPopupModals;
      title: string;
      portfolio: SaverPortfolioExternal.Enum;
      isDisabled: boolean | undefined;
    },
  ): void => {
    if (isDisabled) {
      return;
    }

    e.preventDefault();
    setCurrentPortfolioName(title);

    switch (modal) {
      case VoyagerPopupModals.INVESTABLE_ASSETS:
        setInvestableAssetsPortfolioModal(portfolio);
        break;
      case VoyagerPopupModals.PORTFOLIO_MIX:
        setPortfolioMixModalPortfolio(portfolio);
    }
  };

  const methods = useForm<{
    portfolio?: SaverPortfolio;
    pds: boolean;
  }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });

  const flagsData = useSaverPortfolioFlags();

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createProductInstanceAvailable =
    saverPortfolioFlags?.createProductInstanceAvailable ?? true;

  const descriptionRefs = useMemo(() => {
    return portfolios?.map(() => createRef<HTMLDivElement>());
  }, [portfolios]);
  useSetEqualClientHeights(descriptionRefs, !!portfolios);

  const investmentPerformanceRefs = useMemo(() => {
    return portfolios?.map(() => createRef<HTMLDivElement>());
  }, [portfolios]);
  useSetEqualClientHeights(investmentPerformanceRefs, !!portfolios);

  const summaryRefs = useMemo(() => {
    return portfolios?.map(() => createRef<HTMLDivElement>());
  }, [portfolios]);
  useSetEqualClientHeights(summaryRefs, !!portfolios);

  if (!createProductInstanceAvailable) {
    return (
      <Error
        title="Select your portfolio"
        subtitle={
          saverPortfolioFlags?.createProductInstanceNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            if (variant === 'onboarding') {
              logout();
            } else {
              window.history.back();
            }
          },
          trackingProperties: {
            name: 'create_product_instance_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <>
      <VoyagerInvestableAssetsModal
        portfolio={investableAssetsModalPortfolio}
        portfolioName={currentPortfolioName}
        showModal={!!investableAssetsModalPortfolio}
        closeModal={() => setInvestableAssetsPortfolioModal(null)}
      />

      <VoyagerPortfolioMixModal
        portfolio={portfolioMixModalPortfolio}
        portfolioName={currentPortfolioName}
        showModal={!!portfolioMixModalPortfolio}
        closeModal={() => setPortfolioMixModalPortfolio(null)}
      />

      <Stack spaceY="xl">
        <FormProvider {...methods}>
          <Box display="flex">
            <PortfoliosContainer
              $numberOfColumns={portfolios ? portfolios.length : 5}
              data-testid="portfolios-container"
            >
              {portfolios &&
                portfolios
                  ?.filter((a) => a != null)
                  .sort((a, b) => {
                    if (!a) return -1;
                    if (!b) return 1;
                    if (
                      portfoliosHeldByUser?.includes(a.portfolio) &&
                      !portfoliosHeldByUser?.includes(b.portfolio)
                    ) {
                      return 1;
                    }
                    if (
                      !portfoliosHeldByUser?.includes(a.portfolio) &&
                      portfoliosHeldByUser?.includes(b.portfolio)
                    ) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((portfolio, i) => {
                    if (!portfolio) {
                      return;
                    }

                    const { portfolio: externalPortfolio } =
                      voyagerPortfolios[portfolio.portfolio];
                    const gqlPortfolio = fromProtoPortfolio(externalPortfolio);
                    const isCurrentPortfolio = gqlPortfolio
                      ? portfoliosHeldByUser?.includes(gqlPortfolio)
                      : false;
                    return (
                      <VoyagerPortfolioCard
                        key={gqlPortfolio}
                        error={error}
                        descriptionRef={descriptionRefs?.[i]}
                        investmentPerformanceRef={
                          investmentPerformanceRefs?.[i]
                        }
                        isCurrentlyHeldByUser={isCurrentPortfolio ?? false}
                        onOpenTooltipModal={handleTooltipModal}
                        onSelect={() => {
                          onSelectionChange(gqlPortfolio);
                        }}
                        portfolio={portfolio}
                        summaryRef={summaryRefs?.[i]}
                      />
                    );
                  })}
            </PortfoliosContainer>
          </Box>
        </FormProvider>
      </Stack>
    </>
  );
};

interface PortfolioContainerStyleProps {
  readonly $numberOfColumns: number;
}

const PortfoliosContainer = styled.div<PortfolioContainerStyleProps>`
  margin-inline: auto;
  padding-inline: ${getSpace('md')};
  padding-top: ${getSpace('sm')};

  ${match('lg')`
    padding-top: 0;
    max-width: 100vw;
    display: grid;
    ${(props: PortfolioContainerStyleProps) => ` grid-template-columns: repeat(${props.$numberOfColumns}, minmax(335px, 400px)); `}
    column-gap: 1rem;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-gutter: stable both-edges;
  `}

  // ${match('tablet')`
  //   justify-content: center;
  //  `} 

  ${match('xxxxl')`
    justify-content: center;
   `}
`;
