import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { useLogout } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Inline,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  fromProtoPortfolio,
  SaverPortfolio,
  SaverTMDResult,
  toProtoPortfolio,
  useSaverPortfolioFlags,
  useSubmitPreApprovedTargetMarketDeterminationAnswers,
} from '@spaceship-fspl/graphql';
import { VoyagerTmdPreApprovedPortfolios } from '@spaceship-fspl/graphql/src/__generated__/VoyagerTmdPreApprovedPortfolios';
import { capitalizeFirstLetter, InternalRoutes } from '@spaceship-fspl/helpers';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { getSpace, match } from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { SaverPortfolio as SaverPortfolioExternal } from '@spaceship-fspl/types/externalapi';
import { tmdDetailsForPortfolio } from '@spaceship-fspl/voyager';
import { getMostRecentlyTmdRejectedPortfolio } from '@spaceship-fspl/voyager';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { ResourceList } from 'components/resource-list';
import { RouterLink } from 'components/router-link';
import { useNotifications } from 'contexts/notifications';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE, PORTFOLIO_PERFORMANCE } from 'helpers/errors';
import { voyagerPortfolios } from 'helpers/portfolios';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  VoyagerPortfolioSelector,
  VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment,
} from '../components/voyager-portfolio-selector';
import { Routes } from './routes';

const Header = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: ${getSpace('md')};
  margin-right: ${getSpace('md')};
  ${match('lg')`
     width: 80%;
     margin-inline: auto;
  `}
  justify-content: space-between;
`;

const Footer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: ${getSpace('md')};
  margin-right: ${getSpace('md')};
  ${match('lg')`
     width: 80%;
     margin: 0 auto ${getSpace('lg')} auto;
  `}
  justify-content: space-between;
`;

export const VoyagerTmdPreapprovedPortfolios: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const [error, setError] = useState<string>();
  const [selectedPortfolio, setSelectedPortfolio] = useState<SaverPortfolio>();

  const track = useTrack();
  const logout = useLogout();

  const {
    currentPortfolios,
    variant,
    onPortfolioSelect,
    tmdDetailsByPortfolio,
    onTMDEligible,
    navigateToTMDForm,
    saverTMDDetails,
  } = usePortfolioSelection();
  const [setPreApprovedTMDAnswers, setPreApprovedTMDAnswersMeta] =
    useSubmitPreApprovedTargetMarketDeterminationAnswers();

  const { popToast } = useNotifications();
  const flagsData = useSaverPortfolioFlags();
  const resp = useQuery<VoyagerTmdPreApprovedPortfolios>(
    gql`
      query VoyagerTmdPreApprovedPortfolios {
        contact {
          id
          account {
            id
            preApprovedPortfolioInformations {
              id
              ...VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment
            }
          }
        }
      }
      ${VoyagerPortfolioSelector_VoyagerPortfolioInformationsFragment}
    `,
    {
      onError: () => {
        popToast({
          message: PORTFOLIO_PERFORMANCE,
          level: 'error',
        });
      },
    },
  );

  const handleResolveRejectedPortfolioName: () => string | undefined = () => {
    const rejectedPortfolioKey = getMostRecentlyTmdRejectedPortfolio(
      saverTMDDetails.data,
    );
    if (rejectedPortfolioKey) {
      const rejectedPortfolioConfig = voyagerPortfolios[rejectedPortfolioKey];
      return capitalizeFirstLetter(rejectedPortfolioConfig.name?.toLowerCase());
    }
    return;
  };

  const rejectedPortfolioName = handleResolveRejectedPortfolioName();

  const portfolioInformation =
    resp?.data?.contact?.account?.preApprovedPortfolioInformations;
  const hasPreApprovedPortfolios =
    portfolioInformation && portfolioInformation?.length > 0;
  const config = selectedPortfolio
    ? voyagerPortfolios[selectedPortfolio]
    : undefined;
  const selectedPortfolioTitle = config?.title;
  const pdsLink = config?.pds ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const tmdLink = config?.tmd ?? InternalRoutes.IMPORTANT_DOCUMENTS;

  const handleOnSubmit: (
    gqlPortfolio?: SaverPortfolio,
  ) => Promise<void> = async (gqlPortfolio) => {
    if (variant === 'onboarding') {
      track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_PORTFOLIO_CONFIRM);
    }

    // if no portfolio is selected, set error state and stop submission
    if (!gqlPortfolio) {
      setError('Portfolio is required');
      return;
    }

    // old grpc saver portfolio
    const portfolio = toProtoPortfolio(gqlPortfolio);
    // get existing TMD details
    let selectedProductTMDDetails =
      portfolio && tmdDetailsByPortfolio[portfolio];

    if (selectedProductTMDDetails === SaverPortfolioExternal.Enum.UNKNOWN) {
      popToast({
        level: 'error',
        message: GENERIC_ERROR_MESSAGE,
      });
      return;
    }

    if (
      selectedProductTMDDetails?.preApproved &&
      !selectedProductTMDDetails.resubmissionConfirmationRequired
    ) {
      try {
        // trigger mutation to set pre-approved answers in graph
        const result = await setPreApprovedTMDAnswers({
          variables: {
            input: {
              portfolio: gqlPortfolio,
            },
          },
        });

        // get updated tmd details
        selectedProductTMDDetails = tmdDetailsForPortfolio(
          gqlPortfolio,
          result.data?.submitPreApprovedTargetMarketDetermination,
        );
      } catch {
        popToast({
          level: 'error',
          message: GENERIC_ERROR_MESSAGE,
        });
      }
    }
    onPortfolioSelect(portfolio);

    if (
      selectedProductTMDDetails?.result === SaverTMDResult.OK ||
      selectedProductTMDDetails?.result === SaverTMDResult.MANUALLY_APPROVED
    ) {
      onTMDEligible();
    } else {
      navigateToTMDForm();
    }
  };

  const handlePortfolioSelectionChange: (
    gqlPortfolio?: SaverPortfolio,
  ) => void = (gqlPortfolio) => {
    if (!gqlPortfolio) {
      setError('Portfolio is required');
      return;
    }
    const portfolio = toProtoPortfolio(gqlPortfolio);
    onPortfolioSelect(portfolio);
    setSelectedPortfolio(gqlPortfolio);
    setError('');
  };

  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createProductInstanceAvailable =
    saverPortfolioFlags?.createProductInstanceAvailable ?? true;

  if (!createProductInstanceAvailable || resp.error) {
    return (
      <Error
        title="Select your portfolio"
        subtitle={
          saverPortfolioFlags?.createProductInstanceNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            if (variant === 'onboarding') {
              logout();
            } else {
              window.history.back();
            }
          },
          trackingProperties: {
            name: 'create_product_instance_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    !resp.loading && (
      <Stack>
        <Header>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
              <Stack spaceY={'md'}>
                <PageHeading
                  title={`It doesn’t look likely that you’re in the target market for the ${rejectedPortfolioName} Portfolio.`}
                  subtitle={`It’s worth reviewing our PDS and Target Market Determination to understand who the Spaceship Universe Portfolio is designed for.`}
                  textAlign={'left'}
                />

                <RouterLink
                  to={
                    variant === 'onboarding'
                      ? Routes.VOYAGER_ONBOARDING_PORTFOLIO
                      : Routes.PORTFOLIO_ADD
                  }
                  aria-label={`review portfolios again`}
                  trackingProperties={{
                    name: 'voyager_investment_plan_portfolios_update',
                  }}
                >
                  <PresentationLink
                    size="sm"
                    color="indigo.070"
                    icon="chevron"
                    component="span"
                  >
                    Review Portfolio again
                  </PresentationLink>
                </RouterLink>
                {hasPreApprovedPortfolios ? (
                  <Heading variant={4}>
                    Other portfolios that you may be in the target market for
                  </Heading>
                ) : (
                  <Text variant={2}>
                    It doesn&apos;t look likely that you&apos;re in the target
                    market for any other portfolios either.
                  </Text>
                )}
              </Stack>
            </Columns.Column>
          </Columns>
        </Header>
        <Box>
          {error && (
            <Box marginY="md">
              <Inline spaceX="xs" alignY="center" alignX="center">
                <Box lineHeight={0}>
                  <FeatherAlertTriangleIcon size="md" color="red.100" />
                </Box>
                <Text color="red.100" variant={2} isBold>
                  {error}
                </Text>
              </Inline>
            </Box>
          )}
          <VoyagerPortfolioSelector
            portfoliosHeldByUser={
              currentPortfolios?.map((p) => fromProtoPortfolio(p) || null) ?? []
            }
            error={error}
            onSelectionChange={handlePortfolioSelectionChange}
            portfolios={portfolioInformation ?? []}
            variant="onboarding"
          />
          <Footer>
            <Columns alignX="center" spaceY="xl">
              <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
                {hasPreApprovedPortfolios && (
                  <>
                    <Box marginBottom={'md'}>
                      <Text variant={2} color="neutral.085" align="center">
                        All fees and costs are inclusive of GST and net of
                        expected reduced input tax credits. Third-party fees may
                        apply. Find out more by reading the PDS.
                      </Text>
                    </Box>

                    <Box marginY={'md'}>
                      <Stack spaceY="md">
                        <Stack spaceY="sm">
                          <Heading variant={4} isBold component="h3">
                            Sign up{' '}
                            {selectedPortfolioTitle
                              ? `for the ${selectedPortfolioTitle}`
                              : `to a${
                                  variant === 'add' ? ' new' : ''
                                } Spaceship Voyager portfolio`}
                          </Heading>

                          <Text variant={2}>
                            By clicking the{' '}
                            <strong>&apos;Confirm selection&apos;</strong>{' '}
                            button, I confirm I understand and accept the
                            important documents below and confirm that the
                            product is consistent with my objectives, financial
                            situation and needs.
                          </Text>
                        </Stack>

                        <Box>
                          <Divider color="neutral.050" />

                          <ResourceList
                            resources={[
                              {
                                label: 'Product Disclosure Statement',
                                link: pdsLink,
                              },
                              {
                                label: 'Reference Guide',
                                link: InternalRoutes.REFERENCE_GUIDE,
                              },
                              {
                                label: 'Target Market Determination Statement',
                                link: tmdLink,
                              },
                            ]}
                          />

                          <Divider color="neutral.050" />
                        </Box>
                      </Stack>
                    </Box>
                  </>
                )}
                <Box marginY={'md'}>
                  {hasPreApprovedPortfolios && (
                    <PageFormButtonContainer>
                      <PageFormContinueButton
                        onClick={() => handleOnSubmit(selectedPortfolio)}
                        data-testid="confirm-portfolio-selection"
                        isLoading={setPreApprovedTMDAnswersMeta.loading}
                        trackingProperties={{
                          name:
                            variant === 'onboarding'
                              ? 'signup_portfolio_submit'
                              : 'portfolio_registration_select_portfolio_submit',
                        }}
                      >
                        Confirm selection
                      </PageFormContinueButton>
                    </PageFormButtonContainer>
                  )}
                  <Box marginTop="lg">
                    <Text variant={2} color="neutral.080">
                      The information here is only to inform you if you are
                      likely to be in the target market for a portfolio. All
                      information provided, including in response to these
                      questions, is general in nature and has been prepared
                      without taking into account your objectives, needs, and
                      financial situation. You should seek professional advice
                      and consider your own circumstances. Obtain and consider
                      the relevant PDS and TMD before making a decision about
                      our products.
                    </Text>
                  </Box>
                </Box>
              </Columns.Column>
            </Columns>
          </Footer>
        </Box>
      </Stack>
    )
  );
};
