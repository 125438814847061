import { gql } from '@apollo/client';
import {
  Box,
  Divider,
  Heading,
  PowerUpPanel,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { fromProtoPortfolio, SaverPortfolio } from '@spaceship-fspl/graphql';
import { WebAppVoyagerSelectPortfolio_saverPortfolioInformations } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerSelectPortfolio';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  capitalizeFirstLetter,
  formatDate,
  formatPercentage,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  getSpace,
  marginTop,
  match,
  paddingX,
  paddingY,
} from '@spaceship-fspl/styles';
import { SaverPortfolio as SaverPortfolioExternal } from '@spaceship-fspl/types/externalapi';
import { parse } from 'date-fns';
import { VoyagerPopupModals } from 'pages/voyager-select-portfolio';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { toLongDateWithFullMonth } from '../helpers/format';
import { voyagerPortfolios } from '../helpers/portfolios';
import { requiredValidation } from '../helpers/validation';
import { RadioCard } from './radio-card';
import { GaugeIcon, PieChartIcon } from './voyager-portfolio-icons';

type VoyagerPortfolioSelectCardProps = {
  isCurrentlyHeldByUser: boolean;
  onOpenTooltipModal: (
    e: React.MouseEvent<HTMLDivElement>,
    modal: {
      modal: VoyagerPopupModals;
      title: string;
      portfolio: SaverPortfolioExternal.Enum;
      isDisabled: boolean | undefined;
    },
  ) => void;
  onSelect: (portfolio?: SaverPortfolio) => void;
  portfolio: WebAppVoyagerSelectPortfolio_saverPortfolioInformations;
  descriptionRef?: React.RefObject<HTMLDivElement>;
  error?: string;
  investmentPerformanceRef?: React.RefObject<HTMLDivElement>;
  summaryRef?: React.RefObject<HTMLDivElement>;
};

export const VoyagerPortfolioCard_SaverPortfolioInformationFragment = gql`
  fragment VoyagerPortfolioCard_SaverPortfolioInformationFragment on SaverPortfolioInformation {
    id
    portfolio
    name
    title
    accountFeeAllPorfoliosCopy
    accountFeeBalanceCopy
    accountFee
    inceptionDate
    description
    managementFeeCopy
    managementFee
    oneYearPerformancePercentage
    performanceAsAt
    powerUp
    sinceInceptionPerformancePercentage
  }
`;

export const VoyagerPortfolioCard: React.FC<
  VoyagerPortfolioSelectCardProps
> = ({
  portfolio: {
    portfolio,
    title,
    accountFeeAllPorfoliosCopy,
    accountFeeBalanceCopy,
    accountFee,
    inceptionDate,
    description,
    managementFeeCopy,
    managementFee,
    oneYearPerformancePercentage,
    performanceAsAt,
    powerUp,
    sinceInceptionPerformancePercentage,
  },
  error,
  isCurrentlyHeldByUser,
  descriptionRef,
  investmentPerformanceRef,
  summaryRef,
  onOpenTooltipModal,
  onSelect,
}) => {
  const portfolioFallback = voyagerPortfolios[portfolio];

  const { register } = useFormContext<{ portfolio: SaverPortfolio | null }>();

  const {
    portfolio: externalPortfolio,
    investmentPerformanceIn1Yr,
    investmentRiskSummary,
    targetReturn,
    targetReturnGauge,
    investmentRisk,
    investmentRiskGauge,
    suggestedTimeframe,
    shareOfInvestableAssetsStocks,
    shareOfInvestableAssetsStocksPieChart,
    portfolioMix,
    portfolioMixOther,
    newFeesFallback,
  } = portfolioFallback;
  const Icon = portfolioFallback?.icon.stars;

  const inceptionDateParsed = inceptionDate
    ? parse(inceptionDate, 'd MMMM yyyy', new Date())
    : undefined;
  const performanceAsAtDateParsed = performanceAsAt
    ? parse(performanceAsAt, 'd MMMM yyyy', new Date())
    : undefined;

  return (
    portfolio && (
      <PortfolioCard
        key={`${portfolio}-portfolio-card`}
        data-testid={`${portfolio}-portfolio-card`}
      >
        <RadioCard
          data-testid={`${portfolio}-radio-input`}
          paddingRight={{ lg: 'md' }}
          value={portfolio}
          error={!!error}
          disabled={isCurrentlyHeldByUser}
          {...register('portfolio', requiredValidation('Portfolio'))}
          onChange={() => {
            onSelect(fromProtoPortfolio(externalPortfolio));
          }}
        >
          <Box
            aria-label={title.toLowerCase()}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Stack spaceY="sm">
              <Box ref={descriptionRef}>
                <Stack spaceY="lg">
                  <Box position="relative">
                    <Box display="flex" justifyContent="center">
                      <Icon color="indigo.070" size="xxxl" />
                    </Box>

                    {isCurrentlyHeldByUser && (
                      <StyledUnavailableStatus>
                        You&apos;ve already signed up to this portfolio
                      </StyledUnavailableStatus>
                    )}
                  </Box>

                  <Stack spaceY="xxs">
                    <Heading
                      data-testid="portfolio-title"
                      component="h3"
                      variant={4}
                      isBold
                    >
                      {title}
                    </Heading>
                    <Text data-testid="portfolio-description" variant={3}>
                      {description}
                    </Text>
                  </Stack>
                </Stack>
              </Box>

              <Divider color="neutral.050" />
              <Box ref={investmentPerformanceRef}>
                {oneYearPerformancePercentage === 'N/A' &&
                investmentPerformanceIn1Yr &&
                inceptionDate ? (
                  <Box flexGrow={1}>
                    <Stack spaceY="xs">
                      <Stack spaceY="xxxs">
                        <SectionHeading data-testid="investment-perf-heading">
                          Investment performance
                        </SectionHeading>
                        <Text
                          data-testid="investment-perf-1yr"
                          variant={4}
                          color="neutral.080"
                        >
                          {investmentPerformanceIn1Yr}
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Stack spaceY="xs">
                      <Stack spaceY="xxxs">
                        <SectionHeading data-testid="investment-perf-heading">
                          Investment performance
                        </SectionHeading>

                        <Text variant={4} color="neutral.080" isBold>
                          {performanceAsAt
                            ? `As at ${toLongDateWithFullMonth(new Date(performanceAsAt))}`
                            : ''}
                        </Text>
                      </Stack>

                      <SectionData
                        data={[
                          {
                            label: '1 year performance',
                            value: oneYearPerformancePercentage
                              ? formatPercentage(
                                  oneYearPerformancePercentage,
                                  2,
                                )
                              : '—',
                            note:
                              oneYearPerformancePercentage && performanceAsAt
                                ? `Over the year ending ${toLongDateWithFullMonth(new Date(performanceAsAt))}`
                                : '',
                          },
                          {
                            label: 'Annualised performance since Funded Date',
                            value: sinceInceptionPerformancePercentage
                              ? `${formatPercentage(
                                  sinceInceptionPerformancePercentage,
                                  2,
                                )} pa`
                              : '—',
                            note:
                              inceptionDateParsed && performanceAsAtDateParsed
                                ? `${formatDate(
                                    inceptionDate,
                                    'dd MMMM yyyy',
                                  )} to ${formatDate(
                                    performanceAsAt,
                                    'dd MMMM yyyy',
                                  )} (${calcInceptionToPerformanceAsAtDateInMonths(
                                    inceptionDateParsed,
                                    performanceAsAtDateParsed,
                                  )} months)`
                                : '',
                          },
                        ]}
                      />
                    </Stack>

                    <Text variant={4} color="neutral.085" isItalic>
                      Past performance is not a reliable indicator of future
                      performance.
                    </Text>
                  </>
                )}
              </Box>
              <Divider color="neutral.050" />

              <Box ref={summaryRef}>
                <Stack spaceY="xs">
                  <Stack spaceY="xxxs">
                    <SectionHeading>Summary</SectionHeading>

                    <Text variant={4} color="neutral.080">
                      {investmentRiskSummary}
                    </Text>
                  </Stack>

                  <SectionData
                    data={[
                      {
                        label: 'Target return',
                        value: targetReturn,
                        icon: (
                          <GaugeIcon value={targetReturnGauge} width="44px" />
                        ),
                      },
                      {
                        label: 'Investment risk',
                        value: investmentRisk,
                        icon: (
                          <GaugeIcon value={investmentRiskGauge} width="44px" />
                        ),
                      },
                      {
                        label: 'Minimum timeframe',
                        value: suggestedTimeframe,
                      },
                    ]}
                  />

                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <Text variant={3}>
                        Share of investable <br />
                        assets
                      </Text>

                      <Box
                        marginLeft="xxs"
                        data-testid={`${portfolio}-${VoyagerPopupModals.INVESTABLE_ASSETS}`}
                        onClick={(e) =>
                          onOpenTooltipModal(e, {
                            modal: VoyagerPopupModals.INVESTABLE_ASSETS,
                            title,
                            portfolio: externalPortfolio,
                            isDisabled: false,
                          })
                        }
                      >
                        <FeatherInfoIcon color="neutral.070" size="md" />
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="flex-start">
                      <Box marginRight="xxs">
                        <PieChartIcon
                          value={shareOfInvestableAssetsStocksPieChart}
                          width="24"
                          height="24"
                        />
                      </Box>

                      <Text variant={3} isBold>
                        {shareOfInvestableAssetsStocks}
                      </Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      <Text variant={3}>Target portfolio mix</Text>

                      <Box
                        marginLeft="xxs"
                        data-testid={`${portfolio}-${VoyagerPopupModals.PORTFOLIO_MIX}`}
                        onClick={(e) =>
                          onOpenTooltipModal(e, {
                            modal: VoyagerPopupModals.PORTFOLIO_MIX,
                            title,
                            portfolio: externalPortfolio,
                            isDisabled: false,
                          })
                        }
                      >
                        <FeatherInfoIcon color="neutral.070" size="md" />
                      </Box>
                    </Box>

                    <Text variant={3} isBold align="right">
                      {portfolioMix}{' '}
                      {portfolioMixOther ? (
                        <>
                          <br />
                          {portfolioMixOther}
                        </>
                      ) : (
                        ''
                      )}
                    </Text>
                  </Box>
                </Stack>
              </Box>

              <Divider color="neutral.050" />

              <Stack spaceY="xxs">
                <SectionHeading>Management fees and costs</SectionHeading>

                <SectionData
                  data={[
                    {
                      label: 'Monthly fee',
                      value: accountFee ?? newFeesFallback.accountFee,
                      note: capitalizeFirstLetter(
                        accountFeeBalanceCopy ??
                          newFeesFallback.accountFeeBalanceCopy,
                      ),
                      notes: [
                        capitalizeFirstLetter(
                          accountFeeAllPorfoliosCopy ??
                            newFeesFallback.accountFeeAllPorfoliosCopy,
                        ),
                      ],
                    },
                    {
                      label: 'Management fee',
                      value: managementFee ?? newFeesFallback.managementFee,
                      note: capitalizeFirstLetter(
                        managementFeeCopy ?? newFeesFallback.managementFeeCopy,
                      ),
                    },
                  ]}
                />
                {powerUp ? (
                  <Box marginTop={'lg'}>
                    <PowerUpPanel>
                      <Text variant={2} isBold align={'center'}>
                        {powerUp}
                      </Text>
                    </PowerUpPanel>
                  </Box>
                ) : null}
              </Stack>
            </Stack>
          </Box>
        </RadioCard>
      </PortfolioCard>
    )
  );
};

const StyledUnavailableStatus = styled(Text).attrs({
  component: 'span',
  variant: 4,
  color: 'neutral.000',
})`
  ${backgroundColor('neutral.100')}
  ${borderRadius('sm')}
  ${paddingX('xxs')}
  ${paddingY('xxxs')}
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const SectionHeading: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Heading component="h4" variant={5} isBold>
    {children}
  </Heading>
);

interface SectionDataProps {
  data: Array<{
    icon?: React.ReactNode;
    label: string;
    value: string;
    note?: string;
    notes?: Array<string>;
  }>;
}

const SectionData: React.FC<React.PropsWithChildren<SectionDataProps>> = ({
  data,
}) => {
  return (
    <Stack spaceY="xs">
      {data.map(({ icon, label, value, note, notes }) => (
        <Stack spaceY="xxxs" key={label}>
          <Box display="flex">
            <Box flex={1} marginRight="md">
              <Text variant={3}>{label}</Text>
            </Box>
            <Box display="flex" alignItems="flex-start">
              {icon ? <Box marginRight="xxs">{icon}</Box> : null}
              <Text variant={3} isBold>
                {value}
              </Text>
            </Box>
          </Box>

          {note && (
            <Text variant={4} color="neutral.085">
              {note}
            </Text>
          )}

          {notes && notes?.length == 1 && (
            <Text variant={4} color="neutral.085">
              {notes[0]}
            </Text>
          )}

          {notes && notes?.length > 1 && (
            <StyledList component="ul">
              {notes.map((n, index) => (
                <li key={index}>{n}</li>
              ))}
            </StyledList>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

const PortfolioCard = styled.div`
  margin-inline: auto;
  max-width: 400px;
  min-width: 335px;
  padding-top: ${getSpace('xxs')};
  padding-bottom: ${getSpace('sm')};
  ${match('lg')`
    margin-inline: 0;
  `}
`;

const StyledList = styled(Text).attrs({
  color: 'neutral.085',
  variant: 4,
})`
  ${marginTop('xxs')}
  padding-inline-start: 18px;
`;
