import {
  Beneficiary,
  BeneficiaryBase,
  BeneficiaryIntent,
  Created,
  DocumentDetails,
  DocumentSummary,
  FundSearchOperation,
  FundSearchResult,
  InlineResponse20019Data,
  InvestmentAllocation,
  InvestmentBalance,
  InvestmentBalanceAllocation,
  InvestmentOption,
  ListMemberBalanceOverTimeParams,
  Member,
  MemberBalance,
  MemberBalanceOverTime,
  MemberUpdate,
  OtpResource,
  OtpVerifyResource,
  PaginationMeta,
  RequestAccepted,
  RolloverRequest,
  SargonApiClient,
  Transaction as SuperTransaction,
  UnitPrice,
} from '@sargon/api-client';
import { useCanReadSuper, useEnsureToken } from '@spaceship-fspl/auth';
import { useAuthenticatedFetch } from '@spaceship-fspl/data';
import { FetchFunction } from '@spaceship-fspl/fetch';
import { ErrorWithCode, rethrowError } from '@spaceship-fspl/helpers';
import { api } from '@spaceship-fspl/types/externalapi';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import pTimeout from 'p-timeout';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import {
  AmplifyAuthApiError,
  AmplifyAuthLoginErrorCodes,
  isOtpError,
} from './error';

const NETWORK_TIMEOUT_IN_MS = 30000; // 30 seconds (matching apollo)

export interface OnOTPRequiredFunction {
  (verified: () => void, unverified: () => void): void;
}

interface PrivilegeFunction {
  <Data>(fn: () => Promise<Data>): Promise<Data>;
}

const usePrivilegeProvider = (
  onOTPRequired?: OnOTPRequiredFunction,
): PrivilegeFunction => {
  const promise = useRef<Promise<boolean> | undefined>(undefined);

  return useCallback<PrivilegeFunction>(
    async function (fn) {
      // try and fetch the data without providing an OTP
      try {
        return await fn();
      } catch (error) {
        const isOTPRequired = await isOtpError(error as Response);
        if (!isOTPRequired) {
          throw error;
        }

        // request an OTP if one hasn't already been requested
        if (!promise.current) {
          promise.current = new Promise((resolve) => {
            if (onOTPRequired) {
              onOTPRequired(
                () => resolve(true),
                () => resolve(false),
              );
            } else {
              resolve(false);
            }
          });
        }

        // try and fetch the data again if we've verified an OTP
        const privileged = await promise.current;
        if (privileged) {
          const x = await fn();
          promise.current = undefined;
          return x;
        } else {
          promise.current = undefined;
          throw error;
        }
      }
    },
    [onOTPRequired],
  );
};

export interface AmplifyAuthConfig {
  userPoolId: string;
  userPoolWebClientId: string;
}

interface AmplifyAuth {
  login: (email: string, password: string) => Promise<string | undefined>;
  logout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  forgotPasswordSubmit: (
    email: string,
    otp: string,
    newPassword: string,
  ) => Promise<void>;
  getAuthToken: () => Promise<string | undefined>;
}

const useAmplifyAuthProvider = (): AmplifyAuth => {
  const login = useCallback(
    async (email: string, password: string): Promise<string | undefined> => {
      try {
        await Auth.signOut();
        const user: CognitoUser = await Auth.signIn(
          email.toLowerCase(),
          password,
        );
        const session = user.getSignInUserSession();
        if (session) {
          return session.getAccessToken().getJwtToken();
        }
      } catch (error) {
        const castedError = error as ErrorWithCode;

        switch (castedError.code) {
          case AmplifyAuthLoginErrorCodes.USER_NOT_CONFIRMED:
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            throw new AmplifyAuthApiError(
              castedError.code,
              castedError.message,
            );

          case AmplifyAuthLoginErrorCodes.PASSWORD_RESET_REQUIRED:
            // Mandatory password reset is required, this may be because they are
            // a legacy user or their account has been flagged for reset in
            // Cognito.
            throw new AmplifyAuthApiError(
              castedError.code,
              castedError.message,
            );

          case AmplifyAuthLoginErrorCodes.NOT_AUTHORIZED:
          case AmplifyAuthLoginErrorCodes.USER_NOT_FOUND:
            throw new AmplifyAuthApiError(
              castedError.code,
              'Invalid email or password.',
            );

          default:
            throw new AmplifyAuthApiError(
              castedError.code,
              castedError.message,
            );
        }
      }
      return;
    },
    [],
  );

  const logout = useCallback(async (): Promise<void> => {
    await Auth.signOut();
  }, []);

  const forgotPassword = useCallback(async (email: string): Promise<void> => {
    await Auth.forgotPassword(email.toLowerCase());
  }, []);

  const forgotPasswordSubmit = useCallback(
    async (email: string, otp: string, newPassword: string): Promise<void> => {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), otp, newPassword);
    },
    [],
  );

  const getAuthToken = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch {
      return undefined;
    }
  }, []);

  return {
    login,
    logout,
    forgotPassword,
    forgotPasswordSubmit,
    getAuthToken,
  };
};

const SargonClientContext = React.createContext<SargonApiClient | undefined>(
  undefined,
);
const PrivilegeContext = React.createContext<PrivilegeFunction | undefined>(
  undefined,
);
const AmplifyAuthContext = React.createContext<AmplifyAuth | undefined>(
  undefined,
);

export interface ProviderProps {
  amplifyAuthConfig: AmplifyAuthConfig;
  client: SargonApiClient;
  onOTPRequired?: OnOTPRequiredFunction;
}

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  amplifyAuthConfig,
  client,
  children,
  onOTPRequired,
}) => {
  const privilege = usePrivilegeProvider(onOTPRequired);
  const amplifyAuth = useAmplifyAuthProvider();

  Amplify.configure({
    Auth: {
      region: 'ap-southeast-2',
      identityPoolRegion: 'ap-southeast-2',
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      ...amplifyAuthConfig,
    },
  });

  return (
    <SargonClientContext.Provider value={client}>
      <PrivilegeContext.Provider value={privilege}>
        <AmplifyAuthContext.Provider value={amplifyAuth}>
          {children}
        </AmplifyAuthContext.Provider>
      </PrivilegeContext.Provider>
    </SargonClientContext.Provider>
  );
};

export const useSargonClient = (): SargonApiClient => {
  const context = useContext(SargonClientContext);
  if (!context) {
    throw new Error('@spaceship-fspl/super: Please wrap in <Provider/>.');
  }
  return context;
};

const usePrivilege = (): PrivilegeFunction => {
  const context = useContext(PrivilegeContext);
  if (!context) {
    throw new Error('@spaceship-fspl/super: Please wrap in <Provider/>.');
  }
  return context;
};

export const useAmplifyAuth = (): AmplifyAuth => {
  const context = useContext(AmplifyAuthContext);
  if (!context) {
    throw new Error('@spaceship-fspl/super: Please wrap in <Provider/>.');
  }
  return context;
};

export enum QueryKeys {
  NonBindingBeneficiaries = 'super.non-binding-beneficiaries',
  Beneficiaries = 'super.beneficiaries',
  LatestBeneficiaryIntent = 'super.latest-beneficiary-intent',
  Documents = 'super.documents',
  DocumentDetails = 'super.document-details',
  FutureCashAllocations = 'super.future-cash-allocations',
  InvestmentOptions = 'super.investment-options',
  InvestmentsBalance = 'super.investments-balance',
  LatestFundSearchResult = 'super.latest-fund-search-result',
  ListFundSearchResult = 'super.list-fund-search-result',
  ListRolloverRequests = 'super.list-rollover-requests',
  ListMemberBalanceOverTime = 'super.list-member-balance-over-time',
  ListAllMemberBalanceOverTime = 'super.list-all-member-balance-over-time',
  Member = 'super.member',
  MemberBalance = 'super.member-balance',
  PerformFundSearch = 'super.perform-fund-search',
  PortfolioCompanies = 'super.portfolio-companies',
  Product = 'super.product',
  Transactions = 'super.transactions',
  UnitPrices = 'super.unit-prices',
}

export const useIsValidSuperMember = (): boolean => {
  const { data: member } = useGetMember();
  return useCanReadSuper() && !!member;
};

export const createSuperProduct =
  (fetch: FetchFunction) =>
  async (
    payload: api.external.ICreateSargonMemberRequestBody,
  ): Promise<api.external.ICreateSargonMemberResponseBody> =>
    await fetch({
      url: '/super/sargon',
      method: 'POST',
      body: api.external.CreateSargonMemberRequestBody.fromObject(payload),
    });

export const useCreateSuperProduct = (): UseMutationResult<
  api.external.ICreateSargonMemberResponseBody,
  undefined,
  api.external.ICreateSargonMemberRequestBody,
  undefined
> => {
  const queryClient = useQueryClient();
  const fetch = useAuthenticatedFetch();

  return useMutation(createSuperProduct(fetch), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.Product);
      queryClient.invalidateQueries(QueryKeys.Member);
      queryClient.invalidateQueries(QueryKeys.InvestmentOptions);
    },
  });
};

export const useRecordSuperMatchConsent = (): UseMutationResult<
  void,
  undefined,
  void,
  undefined
> => {
  const fetch = useAuthenticatedFetch();

  return useMutation(async () => {
    await fetch({
      url: '/super/super-match/consent',
      method: 'POST',
    });
  });
};

export const useGetSuperPortfolioCompanies = (
  portfolioKey?: string | null,
): UseQueryResult<api.external.IGetSuperPortfolioCompaniesByPortfolioResponseBody> => {
  const fetch = useAuthenticatedFetch();
  const isSuperUser = useCanReadSuper();
  return useQuery(
    [QueryKeys.PortfolioCompanies, portfolioKey],
    async () =>
      api.external.GetSuperPortfolioCompaniesByPortfolioResponseBody.fromObject(
        await fetch({
          url: `/content/super/portfolio?portfolio=${portfolioKey?.toUpperCase()}`,
          method: 'GET',
        }),
      ),
    { enabled: isSuperUser && !!portfolioKey },
  );
};

export const useRequestMemberOtp = (): UseMutationResult<
  OtpResource | undefined,
  unknown,
  void,
  unknown
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const isSuperUser = useCanReadSuper();
  const amplifyAuth = useAmplifyAuth();

  return useMutation(async () => {
    const accessToken = isSuperUser
      ? await ensureToken()
      : await amplifyAuth.getAuthToken();
    const { data } = await client
      .oTP({ accessToken })
      .requestMemberOtp('me', { scope: 'write' });
    return data;
  });
};

export const useVerifyMemberOtp = (): UseMutationResult<
  OtpVerifyResource | undefined,
  unknown,
  string,
  unknown
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const isSuperUser = useCanReadSuper();
  const amplifyAuth = useAmplifyAuth();

  return useMutation(async (xSuperOTP: string) => {
    const accessToken = isSuperUser
      ? await ensureToken()
      : await amplifyAuth.getAuthToken();
    const { data } = await client
      .oTP({ accessToken })
      .verifyMemberOtp('me', { xSuperOTP });
    return data;
  });
};

export const useGetMember = (): UseQueryResult<
  Member | undefined,
  Response
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useCanReadSuper();

  return useQuery(
    QueryKeys.Member,
    async () => {
      const accessToken = await ensureToken();
      const { data } = await pTimeout(
        client.members({ accessToken }).getMember('me'),
        NETWORK_TIMEOUT_IN_MS,
      );
      return data;
    },
    {
      enabled,
      retry: (failureCount: number, error: Response) => {
        // https://api-docs.certane.com/#operation/getMember
        // 401 Unauthorized | 403 Forbidden | 404 Not Found
        // No point retrying if any of these errors
        if (error.status >= 400 && error.status < 500) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );
};

export type UseUpdateMemberResults = UseMutationResult<
  Member | undefined,
  undefined,
  MemberUpdate,
  undefined
>;

export const useUpdateMember = (): UseUpdateMemberResults => {
  const queryClient = useQueryClient();
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const privilege = usePrivilege();
  const isSuperUser = useCanReadSuper();
  const amplifyAuth = useAmplifyAuth();

  return useMutation(
    async (payload: MemberUpdate): Promise<Member | undefined> => {
      const accessToken = isSuperUser
        ? await ensureToken()
        : await amplifyAuth.getAuthToken();
      return privilege(async () => {
        const { data } = await client
          .members({ accessToken })
          .updateMember('me', payload);
        return data;
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.Member);
        queryClient.invalidateQueries(QueryKeys.InvestmentOptions);
        queryClient.invalidateQueries(QueryKeys.FutureCashAllocations);
      },
    },
  );
};

export const useGetMemberBalance = (): UseQueryResult<
  MemberBalance | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    QueryKeys.MemberBalance,
    async () => {
      const accessToken = await ensureToken();

      try {
        const { data } = await client
          .balance({ accessToken })
          .getMemberBalance('me');
        return data;
      } catch (error) {
        const castedError = error as (Error & { status?: number }) | undefined;
        // https://api-docs.certane.com/#operation/getMemberBalance
        // 404 will be returned until user has a transaction so treat it as empty instead of an error
        if (castedError?.status === 404) {
          return {};
        } else {
          return rethrowError(error);
        }
      }
    },
    {
      enabled,
    },
  );
};

export const useGetInvestmentsBalance = (): UseQueryResult<
  Array<InvestmentBalance> | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    QueryKeys.InvestmentsBalance,
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .investments({ accessToken })
        .listInvestmentsBalance('me');
      return data;
    },
    {
      enabled,
    },
  );
};

export const useRequestInvestmentRebalanceTransfer = (): UseMutationResult<
  RequestAccepted | undefined,
  undefined,
  InvestmentBalanceAllocation,
  undefined
> => {
  const queryClient = useQueryClient();
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();
  const client = useSargonClient();

  return useMutation(
    async (allocation: InvestmentBalanceAllocation) => {
      const accessToken = await ensureToken();
      return privilege(async () => {
        const { data } = await client
          .investments({ accessToken })
          .requestInvestmentRebalanceTransfer('me', [allocation]);
        return data;
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.Member);
        queryClient.invalidateQueries(QueryKeys.FutureCashAllocations);
      },
    },
  );
};

export const useGetBindingBeneficiaries = (): UseQueryResult<
  Beneficiary[] | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();

  return useQuery(QueryKeys.NonBindingBeneficiaries, async () => {
    const accessToken = await ensureToken();
    const { data } = await client
      .beneficiaries({ accessToken })
      .listMemberBeneficiaries('me');
    return data;
  });
};

export const useGetNonBindingBeneficiaries = (): {
  isLoading: boolean;
  data?: Beneficiary[];
} => {
  const { data, isLoading } = useGetBindingBeneficiaries();
  const filteredData = useMemo(
    () =>
      data?.filter(
        ({ beneficiaryType }) =>
          beneficiaryType === BeneficiaryBase.BeneficiaryTypeEnum.NonBinding,
      ),
    [data],
  );
  return {
    isLoading,
    data: filteredData,
  };
};

export const useGetLatestBeneficiaryIntent = (): UseQueryResult<
  BeneficiaryIntent | undefined,
  Response
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();

  return useQuery(
    QueryKeys.LatestBeneficiaryIntent,
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .beneficiaries({ accessToken })
        .getLatestMemberBeneficiaryIntent('me');

      return data;
    },
    {
      retry: 0,
    },
  );
};

export const useGetBeneficiaries = (): UseQueryResult<
  Array<Beneficiary> | undefined,
  Response
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();

  return useQuery(
    QueryKeys.Beneficiaries,
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .beneficiaries({ accessToken })
        .listMemberBeneficiaries('me');

      return data;
    },
    {
      retry: 0,
    },
  );
};

export const useSaveBeneficiaryIntent = (): UseMutationResult<
  BeneficiaryIntent | undefined,
  unknown,
  BeneficiaryBase[],
  unknown
> => {
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();
  const client = useSargonClient();

  return useMutation(async (beneficiaries: BeneficiaryBase[]) => {
    const accessToken = await ensureToken();
    return privilege(async () => {
      const { data } = await client
        .beneficiaries({ accessToken })
        .createMemberBeneficiaryIntent('me', {
          newBeneficiaryIntent: { beneficiaries },
        });
      return data;
    });
  });
};

export type Transaction = SuperTransaction;

interface UseGetTransactionsProps {
  params?: { limit?: number };
  accountId?: string | null;
}

export const useGetTransactions = (
  props?: UseGetTransactionsProps,
): UseInfiniteQueryResult<{
  data?: SuperTransaction[];
  meta?: PaginationMeta;
}> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember() && !!props?.accountId;

  const pageSize = props?.params?.limit || 100;

  return useInfiniteQuery(
    [QueryKeys.Transactions, pageSize, props?.accountId],
    async (pageParamsProps) => {
      const pageOffset = pageParamsProps.pageParam?.offset + pageSize || 0;
      const accessToken = await ensureToken();

      const { data, meta } = await client
        .transactions({ accessToken })
        .listTransactions({
          pageSize,
          pageOffset,
        });
      return { data, meta };
    },
    {
      enabled,
      getNextPageParam: (nextPageParam) => {
        let hasNextPage = false;
        const meta = nextPageParam?.meta;
        if (meta) {
          const offset = meta.offset || 0;
          const total = meta.total || 0;
          const count = meta.count || 0;
          hasNextPage = offset + count < total;
        }
        return hasNextPage ? meta : undefined;
      },
    },
  );
};

const filterAllowedInvestmentOptions = (
  data?: InvestmentOption[],
): InvestmentOption[] =>
  data?.filter(
    ({ legacy, frozen, key }) => !legacy && !frozen && key && key !== 'cash',
  ) || [];

export const getInvestmentOptions =
  (client: SargonApiClient, accessToken?: string) =>
  async (): Promise<InvestmentOption[]> => {
    const { data } = await client
      .investments({ accessToken })
      .listInvestments();
    return filterAllowedInvestmentOptions(data);
  };

export const useGetInvestmentOptions = (): UseQueryResult<
  InvestmentOption[] | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    QueryKeys.InvestmentOptions,
    async () => {
      const accessToken = await ensureToken();
      return getInvestmentOptions(client, accessToken)();
    },
    { enabled },
  );
};

export const useGetFutureCashAllocations = (): UseQueryResult<
  InvestmentAllocation[] | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    QueryKeys.FutureCashAllocations,
    async () => {
      const accessToken = await ensureToken();

      const { data } = await pTimeout(
        client
          .investments({ accessToken })
          .listMembersFutureCashAllocations('me'),
        NETWORK_TIMEOUT_IN_MS,
      );

      return data;
    },
    { enabled },
  );
};

// Note: This is based on the assumption that we only allow 100% allocation
// into one investment option.
export const useGetCurrentInvestmentAllocation = (): Omit<
  UseQueryResult<InvestmentAllocation[] | undefined>,
  'data'
> & { investmentAllocation: InvestmentAllocation | undefined } => {
  const { data, ...rest } = useGetFutureCashAllocations();
  return {
    investmentAllocation: data?.find((o) => o.percent === 100),
    ...rest,
  };
};

export const updateFutureCashAllocations =
  (client: SargonApiClient, accessToken?: string) =>
  async (
    allocation: InvestmentAllocation,
  ): Promise<InvestmentAllocation[] | undefined> => {
    const { data } = await client
      .investments({ accessToken })
      .updateFutureCashAllocations('me', [allocation]);
    return data;
  };

export const useUpdateFutureCashAllocations = (): UseMutationResult<
  Array<InvestmentAllocation> | undefined,
  undefined,
  InvestmentAllocation,
  undefined
> => {
  const queryClient = useQueryClient();
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();
  const client = useSargonClient();

  return useMutation(
    async (allocation: InvestmentAllocation) => {
      const accessToken = await ensureToken();
      return privilege(async () =>
        updateFutureCashAllocations(client, accessToken)(allocation),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.Member);
        queryClient.invalidateQueries(QueryKeys.FutureCashAllocations);
      },
    },
  );
};

export const useGetAllInvestmentPerformance = (
  investmentId: string,
): UseQueryResult<Array<UnitPrice> | undefined> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember() && !!investmentId;
  const sort = 'desc';
  const pageSize = 100;

  return useQuery(
    [QueryKeys.UnitPrices, investmentId],
    async () => {
      const accessToken = await ensureToken();
      let unitPriceData: Array<UnitPrice> = [];
      let pageOffset = 0;
      let hasMore = true;

      while (hasMore) {
        try {
          const { data, meta } = await client
            .investments({ accessToken })
            .getInvestmentPerformance(investmentId, {
              sort,
              pageSize,
              pageOffset,
            });

          if (data) {
            unitPriceData = unitPriceData.concat(data);
          }
          pageOffset += pageSize;
          hasMore = !!meta?.total && pageOffset < meta.total;
        } catch (error) {
          hasMore = false;
          return rethrowError(error);
        }
      }
      return unitPriceData;
    },
    { enabled },
  );
};

export const useGetDocuments = (): UseQueryResult<
  Array<DocumentSummary> | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    QueryKeys.Documents,
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .documents({ accessToken })
        .listMemberDocuments('me');
      return data;
    },
    {
      enabled,
    },
  );
};

export const useGetDocumentDetails = (
  documentId?: string,
): UseQueryResult<DocumentDetails | undefined> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember() && !!documentId;

  return useQuery(
    [QueryKeys.DocumentDetails, documentId],
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .documents({ accessToken })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .getMemberDocument('me', documentId!);
      return data;
    },
    { enabled, staleTime: 300000 /* 5 minutes */ },
  );
};

export type ListRolloverRequests = InlineResponse20019Data;

export const useListRolloverRequests = (): UseQueryResult<
  InlineResponse20019Data | undefined
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    [QueryKeys.ListRolloverRequests],
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .fundSearchRollover({ accessToken })
        .listRolloverRequests('me');
      return data;
    },
    { enabled },
  );
};

export const useCreateManualRolloverRequest = (): UseMutationResult<
  Created | undefined,
  unknown,
  RolloverRequest,
  unknown
> => {
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();
  const client = useSargonClient();

  return useMutation(async (rolloverRequest: RolloverRequest) => {
    const accessToken = await ensureToken();
    return privilege(async () => {
      const { data } = await client
        .fundSearchRollover({ accessToken })
        .createManualRolloverRequest('me', rolloverRequest);
      return data?.created;
    });
  });
};

export const useListAllMemberBalanceOverTime = (): UseQueryResult<
  MemberBalanceOverTime[]
> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember();

  return useQuery(
    [QueryKeys.ListAllMemberBalanceOverTime],
    async () => {
      const accessToken = await ensureToken();
      const pageSize = 100;

      let balanceData: Array<MemberBalanceOverTime> = [];
      let pageOffset = 0;
      let hasMore = true;

      while (hasMore) {
        try {
          const { data, meta } = await client
            .balance({ accessToken })
            .listMemberBalanceOverTime('me', {
              frequency: 'daily',
              pageSize,
              pageOffset,
            });

          if (data) {
            balanceData = balanceData.concat(data);
          }
          pageOffset += pageSize;
          hasMore = !!meta?.total && pageOffset < meta.total;
        } catch (error) {
          hasMore = false;
          return rethrowError(error);
        }
      }

      return balanceData;
    },
    { enabled },
  );
};

export const useListMemberBalanceOverTime = ({
  isEnabled = true,
  params,
}: {
  isEnabled?: boolean;
  params: ListMemberBalanceOverTimeParams;
}): UseQueryResult<MemberBalanceOverTime[]> => {
  const ensureToken = useEnsureToken();
  const client = useSargonClient();
  const enabled = useIsValidSuperMember() && isEnabled;

  return useQuery(
    [
      QueryKeys.ListMemberBalanceOverTime,
      params?.frequency,
      params?.pageSize,
      params?.pageOffset,
    ],
    async () => {
      const accessToken = await ensureToken();
      const { data } = await client
        .balance({ accessToken })
        .listMemberBalanceOverTime('me', params);
      return data;
    },
    { enabled },
  );
};

export const usePerformFundSearch = ({
  isEnabled = true,
  retryDelay,
  retry,
  onSuccess,
}: {
  isEnabled?: boolean;
  retryDelay?: number;
  retry?: number;
  onSuccess?: () => void;
}): UseQueryResult<FundSearchOperation> => {
  const client = useSargonClient();
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();

  return useQuery(
    [QueryKeys.PerformFundSearch],
    async () => {
      const accessToken = await ensureToken();
      return privilege(async () => {
        const { data } = await client
          .fundSearchRollover({ accessToken })
          .performFundSearch('me');
        return data;
      });
    },
    {
      onSuccess,
      retryDelay,
      retry,
      enabled: isEnabled,
    },
  );
};

export const useListFundSearchResult = ({
  fundSearchId,
  isEnabled = true,
  retryDelay,
  retry,
}: {
  fundSearchId?: string;
  isEnabled?: boolean;
  retryDelay?: number;
  retry?: number;
}): UseQueryResult<FundSearchResult> => {
  const client = useSargonClient();
  const ensureToken = useEnsureToken();
  const isValidSuperMember = useIsValidSuperMember();
  const privilege = usePrivilege();

  return useQuery(
    [QueryKeys.ListFundSearchResult],
    async () => {
      const accessToken = await ensureToken();
      return privilege(async () => {
        const { data } = await client
          .fundSearchRollover({ accessToken })
          .listFundSearchResult('me', fundSearchId || '');

        return data;
      });
    },
    {
      retryDelay,
      retry,
      enabled: !!fundSearchId && isValidSuperMember && isEnabled,
    },
  );
};

export const useGetLatestFundSearchResult = ({
  isEnabled = true,
}: {
  isEnabled?: boolean;
}): UseQueryResult<FundSearchResult> => {
  const client = useSargonClient();
  const ensureToken = useEnsureToken();
  const privilege = usePrivilege();

  return useQuery(
    [QueryKeys.LatestFundSearchResult],
    async () => {
      const accessToken = await ensureToken();
      return privilege(async () => {
        const { data } = await client
          .fundSearchRollover({ accessToken })
          .getLatestFundSearchResult('me');

        return data;
      });
    },
    {
      enabled: isEnabled,
      retry: 0,
    },
  );
};
