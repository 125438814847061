import { ApolloClient } from '@apollo/client';
import { useBoolean, warmCache } from '@spaceship-fspl/dynamic-config';

export enum DynamicConfigKeys {
  APP_DISABLED = 'emergency_web_disabled',
  APP_DISABLED_COPY = 'emergency_app_disabled_copy',
  BOOST_ITEM_GROUP_AUD_LOCK_MINIMUM_THRESHOLD = 'boost_item_group_aud_lock_minimum_threshold',
  FHSS_MAX_TOTAL_AUD_AMOUNT = 'fhss_max_total_aud_amount',
  INVESTMENT_PLAN_PUSH_COPY = 'investment_plan_push_copy',
  NOVA_W8BEN_FAQ_LINK = 'nova_w8ben_faq_link',
  MFA_REGISTRATION_CARD_COPY = 'mfa_registration_card_copy',
  SFT_ONE_SUPER_GO_LIVE_DATE = 'sft_one_super_go_live_date',
  SUPER_BPAY_BILLER_CODE = 'super_bpay_biller_code',
}

export enum FeatureFlagKeys {
  BAV_BLACKLIST_SAVINGS_ACCOUNTS_ENABLED = 'web_bav_blacklist_savings_accounts_enabled',
  BOOSTS_DEBUG = 'web_boosts_debug_enabled',
  CANCEL_TRANSACTION_APPLICATION_ENABLED = 'web_cancel_transaction_application_enabled',
  CHANGE_PASSWORD_ENABLED = 'change_password_enabled',
  FHSS_ENABLED = 'web_fhss_coming_soon_enabled',
  GOOGLE_MAPS_AUTOCOMPLETE_ENABLED = 'web_google_maps_autocomplete_enabled',
  GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_ENABLED = 'web_green_id_drivers_licence_card_number_enabled',
  GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_QLD_ENABLED = 'web_green_id_drivers_licence_card_number_qld_enabled',
  GREEN_ID_DRIVERS_LICENCE_CARD_NUMBER_VIC_ENABLED = 'web_green_id_drivers_licence_card_number_vic_enabled',
  MULTI_PORTFOLIO_ENABLED = 'web_multi_portfolio_enabled',
  SECURITY_CHECK_ENABLED = 'web_security_check_enabled',
  SUPER_CROSS_SELL = 'web_super_xsell_enabled',
  SUPER_RETURNS_ENABLED = 'super_returns_visible',
  SUPERMATCH_ENABLED = 'web_supermatch_enabled',
  VOYAGER_CROSS_SELL = 'web_voyager_xsell_enabled',
  VOYAGER_GRAPH_PERCENTAGE_CHANGES_ENABLED = 'voyager_graph_percentage_changes_enabled',
  NOVA_RESTRICTED_COUNTRY_ENABLED = 'web_nova_restricted_country_enabled',
  MFA_REGISTRATION_LOGGED_IN_ENABLED = 'mfa_registration_logged_in_enabled',
  MFA_REGISTRATION_ONBOARDING_ENABLED = 'mfa_registration_onboarding_enabled',
  SUPER_UNIT_PRICE_MOVEMENT_VISIBLE = 'super_unit_price_movement_visible',
  PERCY_TMD_PHASE_ONE_ENABLED = 'percy_tmd_phase_one_enabled',
  PERCY_TMD_PHASE_TWO_ENABLED = 'percy_tmd_phase_two_enabled',
  MONEY_DAY_ONE_ENABLED = 'money_day_one_enabled',
}

export const voyagerFeatureFlags = [
  FeatureFlagKeys.GOOGLE_MAPS_AUTOCOMPLETE_ENABLED,
  FeatureFlagKeys.SECURITY_CHECK_ENABLED,
  FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
  FeatureFlagKeys.SUPER_CROSS_SELL,
];
export const superFeatureFlags = [
  FeatureFlagKeys.GOOGLE_MAPS_AUTOCOMPLETE_ENABLED,
  FeatureFlagKeys.SUPERMATCH_ENABLED,
  FeatureFlagKeys.VOYAGER_CROSS_SELL,
];

export const useFeatureFlag = (flag: FeatureFlagKeys): boolean => {
  return useBoolean(flag, false);
};

export async function warmDynamicConfigCache(
  client: ApolloClient<unknown>,
): Promise<void> {
  await warmCache(client, [
    ...Object.values(DynamicConfigKeys),
    ...Object.values(FeatureFlagKeys),
  ]);
}

export enum DynamicConfigDismissibleId {
  TMD_MANUALLY_APPROVED_INDEX_CARD = 'tmd_manually_approved_index_card',
  TMD_MANUALLY_APPROVED_UNIVERSE_CARD = 'tmd_manually_approved_universe_card',
  TMD_MANUALLY_APPROVED_EARTH_CARD = 'tmd_manually_approved_earth_card',
  TMD_MANUALLY_APPROVED_GALAXY_CARD = 'tmd_manually_approved_galaxy_card',
  TMD_MANUALLY_APPROVED_EXPLORER_CARD = 'tmd_manually_approved_explorer_card',
  SUPER_SFT_POST_MIGRATION_INFORMATION_CARD = 'super_sft_post_migration_information_card',
}
