import { AUSSIE_DATE_FORMAT } from '@spaceship-fspl/helpers';
import { google, IAddress } from '@spaceship-fspl/types/externalapi';
import { format, isValid, parse } from 'date-fns';
import { Decimal } from 'decimal.js';

type Locale = 'en-AU';
type Currency = 'AUD';

const LONG_DATE_FORMAT = 'dd MMM yyyy';
const LONG_DATE_FORMAT_WITH_FULL_MONTH = 'dd MMMM yyyy';
const ISO_DATE_FORMAT = 'yyyy-MM-dd';

const formatter = (locale: Locale, currency: Currency): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, { style: 'currency', currency });

const audFormatter = formatter('en-AU', 'AUD');

export const formatAddress = (address?: IAddress | null): string => {
  if (!address) {
    return '';
  }
  const { street_address, suburb, state, postcode } = address;
  return `${street_address}\n${suburb}, ${state}, ${postcode}`.trim();
};

export const formatCurrency = (
  value?: string | number | null,
  formatter: Intl.NumberFormat = audFormatter,
): string => {
  if (typeof value === 'string' && value?.startsWith('$')) {
    value = value.slice(1);
  }

  if (isNaN(Number(value)) || value === '' || value === null) {
    return '—';
  }
  return formatter.format(Number(value));
};

export const fromAussieDate = (value: string): Date => {
  return parse(value, AUSSIE_DATE_FORMAT, new Date());
};

export const fromISODate = (value: string): Date => {
  return parse(value, ISO_DATE_FORMAT, new Date());
};

export const toISODate = (value: Date): string => {
  if (!isValid(value)) {
    return '';
  }
  return format(value, ISO_DATE_FORMAT);
};

export const toAussieDate = (value: Date): string => {
  if (!isValid(value)) {
    return '';
  }
  return format(value, AUSSIE_DATE_FORMAT);
};

export const toLongDate = (value: Date): string => {
  if (!isValid(value)) {
    return '';
  }
  return format(value, LONG_DATE_FORMAT);
};

export const toLongDateWithFullMonth = (value: Date): string => {
  if (!isValid(value)) {
    return '';
  }
  return format(value, LONG_DATE_FORMAT_WITH_FULL_MONTH);
};

export const enumToString = (
  enumType: { [value: number]: string },
  enumValue: number | null,
): string => {
  return enumValue ? enumType[enumValue] || 'UNKNOWN' : 'UNKNOWN';
};

export const enumToPrettyString = (
  enumType: { [value: number]: string },
  enumValue: number | null,
): string => {
  let name = enumValue ? enumType[enumValue] || '' : '';
  name = name.replace(/_/g, ' ');
  return name.charAt(0).toUpperCase() + name.toLowerCase().substr(1);
};

export const timestampToDate = (
  time?: google.protobuf.ITimestamp | null,
): Date | null => {
  return time && typeof time.seconds == 'number' && time.seconds !== 0
    ? new Date(time.seconds * 1000)
    : null;
};

export const formatNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return n + (s[(v - 20) % 10]! || s[v]! || s[0]!);
};

export const camelPad = (str: string): string =>
  str
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();

export const formatLargeNumber = (value: number): string => {
  if (value < 1000) {
    return value.toString();
  }
  const units = ['k', 'm', 'bn', 't'];
  const order = Math.floor(Math.log(value) / Math.log(1000));
  const unitName = units[order - 1];
  const num = Math.floor(value / 1000 ** order);
  return `${num}${unitName}`;
};

export const formatPercentage = (
  value?: string | number,
  precision = 0,
): string => {
  const numberValue = Number(value);
  return isNaN(numberValue) || value === '' || value === null
    ? '—'
    : `${Intl.NumberFormat().format(Number(numberValue.toFixed(precision)))}%`;
};

export const cleanNumber = (num: string): string => num.replace(/[^0-9.]/g, '');

export const truncateDecimal = (n: number, digits = 4): number =>
  new Decimal(n).toDP(digits, Decimal.ROUND_DOWN).toNumber();
